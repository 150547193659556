import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { provideRouter } from '@angular/router';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  imports: [CommonModule, ResetPasswordComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: ResetPasswordComponent,
      },
    ]),
  ],
})
export class ResetPasswordModule {}
