import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BaseComponent } from 'app/presentation/base/base.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { PasswordValidatorUtility } from '../../shared/utilities/password-validator.utility';
import { ResetPasswordPresenter } from './presenter/reset-password.presenter';
import { ResetPasswordSideEffects } from './presenter/reset-password.side-effects';
import { ResetPasswordViewEvent } from './presenter/reset-password.view-events';
import { ResetPasswordViewState } from './presenter/reset-password.view-state';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [LoaderComponent, NgIf, FormsModule, ReactiveFormsModule, NgClass],
  providers: [ResetPasswordPresenter],
})
export class ResetPasswordComponent extends BaseComponent<
  ResetPasswordPresenter,
  ResetPasswordViewState,
  ResetPasswordViewEvent,
  ResetPasswordSideEffects
> {
  public resetPasswordForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidatorUtility.containUpperAndLower({ containUpperAndLower: true }),
        PasswordValidatorUtility.containNumAndSpecialChar({ containNumAndChar: true }),
      ]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: this.passwordMatchCheck.bind(this) },
  );

  public presenter: ResetPasswordPresenter = inject(ResetPasswordPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
  }

  passwordMatchCheck(formGroup: FormGroup): { passwordNotMatch: boolean } | null {
    const { value: password } = formGroup.get('password') as { value: string };
    const { value: confirmPassword } = formGroup.get('confirmPassword') as { value: string };
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  showHidePassword(): void {
    this.presenter.emitViewEvent({ type: 'ToggleShowHidePassword' });
  }

  showHideRepeatedPassword(): void {
    this.presenter.emitViewEvent({ type: 'ToggleShowHideConfirmPassword' });
  }

  get password(): AbstractControl {
    return this.resetPasswordForm.get('password')!;
  }

  onSubmit(): void {
    this.presenter.emitViewEvent({
      type: 'Submit',
      password: this.resetPasswordForm.value.password!,
      confirmPassword: this.resetPasswordForm.value.confirmPassword!,
    });
  }
}
