<div class="resetPassword">
  <loader [loading]="viewState.isLoading"></loader>
  <section class="resetPassword-container">
    <div *ngIf="viewState.errorMessage" class="resetPassword-error">
      <img
        loading="lazy"
        class="resetPassword-error__img"
        src="{{ viewState.assetsRelativePath + 'danger.svg' }}"
      />
      <span class="resetPassword-error__text">{{ viewState.errorMessage }}</span>
    </div>
    <div class="resetPassword-header">
      <img
        loading="lazy"
        src="{{ viewState.assetsRelativePath + 'forgotpassword.svg' }}"
        alt="forgot password"
        class="resetPassword-header__img"
      />
      <h5 class="resetPassword-header__title">تغيير كلمة المرور</h5>
    </div>
    <div class="resetPassword-content">
      <form
        action="submit"
        class="resetPassword-form"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()"
      >
        <div class="resetPassword-form-field">
          <div class="resetPassword-form-field__enterPassword">
            <input
              class="form-control"
              [type]="viewState.showPassword ? 'text' : 'password'"
              placeholder="كلمة المرور الجديدة"
              formControlName="password"
              [readonly]="viewState.isLoading"
            />
            <img
              loading="lazy"
              class="resetPassword-form-field__enterPassword__icon"
              [src]="
                viewState.assetsRelativePath +
                (viewState.showPassword ? 'eye-slash.svg' : 'eye.svg')
              "
              (click)="showHidePassword()"
            />
          </div>
          <div class="resetPassword-form-field__validations">
            <div class="resetPassword-form-field__validations__item">
              <img
                loading="lazy"
                [src]="
                  viewState.assetsRelativePath +
                  (!password.dirty
                    ? 'check-mark.svg'
                    : password.errors?.minlength || password.errors?.required
                    ? 'red-wrong.svg'
                    : 'check-mark-success.svg')
                "
                class="resetPassword-form-field__validations__item__img"
              />
              <span
                class="resetPassword-form-field__validations__item__text"
                [ngClass]="{
                  'resetPassword-form-field__validations__item__text--error':
                    (password.errors?.minlength || password.errors?.required) && password.dirty,
                  'resetPassword-form-field__validations__item__text--success':
                    !password.errors?.minlength && password.dirty && !password.errors?.required
                }"
                >يتكون من ٨ رموز على الاقل</span
              >
            </div>
            <div class="resetPassword-form-field__validations__item">
              <img
                loading="lazy"
                [src]="
                  viewState.assetsRelativePath +
                  (!password.dirty
                    ? 'check-mark.svg'
                    : password.errors?.containUpperAndLower || password.errors?.required
                    ? 'red-wrong.svg'
                    : 'check-mark-success.svg')
                "
                class="resetPassword-form-field__validations__item__img"
              />
              <span
                class="resetPassword-form-field__validations__item__text"
                [ngClass]="{
                  'resetPassword-form-field__validations__item__text--error':
                    (password.errors?.containUpperAndLower || password.errors?.required) &&
                    password.dirty,
                  'resetPassword-form-field__validations__item__text--success':
                    !password.errors?.containUpperAndLower &&
                    password.dirty &&
                    !password.errors?.required
                }"
                >يحتوي على احرف كبيرة و صغيرة</span
              >
            </div>
            <div class="resetPassword-form-field__validations__item">
              <img
                loading="lazy"
                [src]="
                  viewState.assetsRelativePath +
                  (!password.dirty
                    ? 'check-mark.svg'
                    : password.errors?.containNumAndChar || password.errors?.required
                    ? 'red-wrong.svg'
                    : 'check-mark-success.svg')
                "
                class="resetPassword-form-field__validations__item__img"
              />
              <span
                class="resetPassword-form-field__validations__item__text"
                [ngClass]="{
                  'resetPassword-form-field__validations__item__text--error':
                    (password.errors?.containNumAndChar || password.errors?.required) &&
                    password.dirty,
                  'resetPassword-form-field__validations__item__text--success':
                    !password.errors?.containNumAndChar &&
                    password.dirty &&
                    !password.errors?.required
                }"
                >يحتوي على ارقام و رموز مثل @#&</span
              >
            </div>
          </div>
        </div>
        <div class="resetPassword-form-field">
          <div class="resetPassword-form-field__enterPassword">
            <input
              class="form-control"
              [type]="viewState.showConfirmPassword ? 'text' : 'password'"
              placeholder="تأكيد كلمة المرور"
              formControlName="confirmPassword"
              [readonly]="viewState.isLoading"
            />
            <img
              loading="lazy"
              class="resetPassword-form-field__enterPassword__icon"
              [src]="
                viewState.assetsRelativePath +
                (viewState.showConfirmPassword ? 'eye-slash.svg' : 'eye.svg')
              "
              (click)="showHideRepeatedPassword()"
            />
          </div>
          <div
            *ngIf="resetPasswordForm.errors?.passwordNotMatch"
            class="resetPassword-form-field__error"
          >
            <img
              loading="lazy"
              class="resetPassword-form-field__error__img"
              src="{{ viewState.assetsRelativePath + 'danger.svg' }}"
            />
            <span class="resetPassword-form-field__error__text">كلمة المرور لا تتطابق</span>
          </div>
        </div>
        <button
          [disabled]="!resetPasswordForm.valid || viewState.isLoading"
          class="resetPassword-form__submit-button"
          type="submit"
        >
          تأكيد
        </button>
      </form>
    </div>
  </section>
</div>
