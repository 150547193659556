import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordModel } from 'app/core/domain/auth/reset-password.model';
import { ResetPasswordUseCase } from 'app/core/usecases/auth/reset-password.usecase';
import { BasePresenter } from 'app/presentation/base/base.presenter';
import { LOGIN_URL } from 'app/presentation/shared/constants';
import { ResetPasswordSideEffects } from './reset-password.side-effects';
import { ResetPasswordViewEvent } from './reset-password.view-events';
import { ResetPasswordViewState } from './reset-password.view-state';

@Injectable({ providedIn: 'root' })
export class ResetPasswordPresenter extends BasePresenter<
  ResetPasswordViewState,
  ResetPasswordViewEvent,
  ResetPasswordSideEffects
> {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _resetPasswordUseCase: ResetPasswordUseCase,
  ) {
    super();
  }

  protected defaultViewState(): ResetPasswordViewState {
    return {
      id: '',
      resetToken: '',
      errorMessage: '',
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      assetsRelativePath: '/assets/img/auth/',
    };
  }

  protected onViewEvent(event: ResetPasswordViewEvent): void {
    switch (event.type) {
      case 'Init': {
        this._route.params.subscribe({
          next: (params) => {
            this.updateViewState({
              ...this.viewState,
              id: params.id,
              resetToken: params.resetToken,
            });
          },
        });
        break;
      }
      case 'ToggleShowHidePassword': {
        this.updateViewState({ ...this.viewState, showPassword: !this.viewState.showPassword });
        break;
      }
      case 'ToggleShowHideConfirmPassword': {
        this.updateViewState({
          ...this.viewState,
          showConfirmPassword: !this.viewState.showConfirmPassword,
        });

        break;
      }
      case 'Submit': {
        this.updateViewState({ ...this.viewState, isLoading: true });

        const resetPassword: ResetPasswordModel = {
          id: this.viewState.id,
          resetToken: this.viewState.resetToken,
          newPassword: event.password,
          confirmPassword: event.confirmPassword!,
        };

        this._resetPasswordUseCase.execute(resetPassword).subscribe({
          next: () => {
            this.updateViewState({ ...this.viewState, isLoading: false });
            this._router.navigate([LOGIN_URL], { queryParams: { reset: true } });
          },
          error: (err) => {
            let errorMsg = '';
            if (err.status === 404) {
              errorMsg =
                'انتهت صلاحية رابط تعديل كلمة السر، من فضلك قم بطلب تغيير كلمة السر مرة أخرى';
            } else {
              errorMsg = 'يوجد مشكلة في السيرفر، من فضلك حاول مرة أخرى';
            }
            this.updateViewState({ ...this.viewState, isLoading: false, errorMessage: errorMsg });
          },
        });
      }
    }
  }
}
